<template>
  <div>
    <h3 ref="intro">
      Friction: A Contact Force
    </h3>
    <p>
      Frictional force (commonly referred to as just friction) is a form of contact force. Contact force, as the name suggests, is a  force that arises when an object comes in contact with another object. Examples involving contact between two objects include a book resting on a desk, a ball rolling on the floor, or a sled sliding across snow or ice. There are two components of contact force. The component of the contact force that acts normal of the surface is called the normal reaction (often denoted by \(N\) ). The component of the contact force that acts tangential to the contact surface is called the frictional force (often denoted by \(F_R\)).
    </p>
    <h3 ref="deep_dive">
      Types of Friction: Static vs. Kinetic
    </h3>
    <p>
      Friction is a resistive force - meaning it always acts to oppose the relative motion between two surfaces. Depending on whether the relative motion is impending or has already begun, the friction force can be classified as static or kinetic. <br>
      Consider a lawn mower with mass \(m\) at rest on a horizontal lawn.
      The force due to gravity \(W = mg\) acts downward &mdash; which is cancelled by the normal reaction at the contact surface.
      Upon considering the equilibrium of forces in normal direction we obtain
      $$R_N = W =  mg$$
      Now, consider a horizontal force \(F_{ext} \) applied on the mower.
      Experience tells us that a small applied force does not set the mower in motion &mdash; only when the force exceeds a certain magnitude, the block moves.
      However, according to the laws of motion, if \(F_{ext}\) were the only force acting on the block, the block would be immediately
      set in motion with an acceleration \(a = F_{ext}/m\), however small this acceleration is. The fact that the block remains at rest until the applied force reaches a certain magnitude implies that there must be a resistive force active in the horizontal direction that opposes the applied force \(F_{ext}\). This resistive force opposing the impending relative motion between the mower and the lawn
      is known as static frictional force, and is often denoted by \(F_s\). The subscript "s" is used to denote static friction in contrast to kinetic friction (which we will discuss next).
      <br>
      Note that the static friction is a reactive force, meaning it arises in response to the applied external force,
      and cannot exist by itself. Up to a certain value, as the applied force \(F_{ext}\) increases, the frictional force \(F_s\) also increases, remaining equal and opposite to the applied force, and hence keeping the block at rest. This is why it is called static friction.
      However, the static friction has an upper limiting value (denoted by \((F_s)_{max}\)), and as a result, it cannot prevent the block from sliding indefinitely. Once the applied force exceeds this limiting value,
      the block is set in motion. Experiments have shown that the limiting value of static friction \((F_s)_{max}\)
      varies in proportion to the normal reaction:
      $$(F_s)_{max} = \mu_s N$$
      The constant of proportionality \(\mu \) is called the coefficient of static friction,
      and depends only the nature of contacting surfaces. The law of static friction can thus be written as:
      $$F_s \le \mu_s N $$
      <br>
      Experiments have also shown that once the sliding has started, the frictional force opposing the relative motion drops from its limiting value \(F_s)_{max}\) to a lower value. The frictional force acting between two surfaces that are in relative motion is called sliding (or kinetic) friction and is denoted by \(f_k\). The sliding friction, like static friction, has been found to be independent of the area of contact and the sliding velocity. Further, it has been shown to be obeying a law similar to that for static friction:
      $$F_k = \mu_k N $$
      where \(\mu_k\) is known as the coefficient of kinetic friction.
    </p>
    <h3 ref="playgraph">
      MagicGraph | Static Friction Explained
    </h3>
    <p>
      The MagicGraph below explain the concept of static friction.
    </p>
    <v-responsive :aspect-ratio="1">
      <v-layout justify-center>
        <div id="jxgbox1a" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <v-responsive :aspect-ratio="1">
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Joukowsky',
  created: function () {
    const title = 'Forces in Mechanics: Friction';
    // Store mutations
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Friction: A Contact Force', action: () => this.goto('intro')},
      {title: 'Types of Friction', action: () => this.goto('deep_dive')},
      {title: 'Understanding Friction', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    window.onresize = this.resize(this.graphs);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
