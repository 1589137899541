const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-3, 22, 17, -4],keepaspectratio: true, axis:false, ticks:false, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
	var resize = function () {
            brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
            brd1.fullUpdate();
            };
    window.onresize = resize;
	brd1.options.layer['point'] =9;
	brd1.options.layer['segment'] =6;
    brd1.options.layer['polygon'] =2;
    brd1.options.layer['image'] =8;
	brd1.create('text', [4, 20, '<b>Can You Get Puppy His Food?</b>'], {fixed:true, fontSize:function(){return 32*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('text', [0, 18, 'Tip: Friction is your friend! It keeps the bowl from sliding down the ramp.'], {fixed:true, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	
	        var mu = brd1.create('slider', [[0, 12],[6, 12],[0.1, 0.2, 1.2]], {baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'&mu;',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
			//
			var circc=brd1.create('arc', [[0,0],[5, 0], [5*20/Math.sqrt(544),5*12/Math.sqrt(544)]],{visible:true, strokeWidth:0.5, strokeColor:'black', dash:1, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});
	  //
			var lev =brd1.create('glider', [5, 0, circc], {face:'circle', size:3, name:'Drag Me to Lift!', strokeColor:'black', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
			//
			brd1.create('segment', [[0,0],[20,12]],{strokeColor:'black', dash:1, strokeWidth:1});
//
			var A =brd1.create('point', [20,0],{visible:false});
			var B =brd1.create('point', [0,0],{visible:false});
			var sng =brd1.create('angle', [A,B,lev],{radius:6, visible:false, fillOpacity:0, dash:1, strokeColor:'black', label:{fontSize:22, cssStyle:'fontFamily:Oswald; fontColor:black'}});
			//
			//var circ=brd1.create('segment', [[20,0],[20,+12]],{visible:false});
			brd1.create('segment', [[0,0],[20,0]],{strokeColor:'black', dash:1, strokeWidth:1});
			var sld =brd1.create('point', [20, function(){return 20*Math.tan(sng.Value())}], {face:'square', size:4, name:'', shadow:true, strokeColor:'black', visible:false});
			brd1.create('polygon', [[0, 0], [20,0], sld],{vertices:{visible:false, fixed:true}, borders:{visible:false}, fillColor:'brown', fillOpacity:1.0});
			brd1.create('polygon', [[20, 0], [24,0], [24,12], [20, 12]],{vertices:{visible:false, fixed:true}, borders:{visible:false}, fillColor:'grey', fillOpacity:1.0});
			brd1.create('polygon', [[0, 0], [24,0], [24,-5], [0, -5]],{vertices:{visible:false, fixed:true}, borders:{visible:false}, fillColor:'grey', fillOpacity:1.0});
//brd1.create('polygon', [[0, 0], [0,function(){return sld.Y()}], sld],{vertices:{visible:false, fixed:true}, borders:{strokeColor:'black'}, fillColor:'grey', fillOpacity:1, visible:function(){if(sld.Y()<0){return true}else{return false}}});
			//var bowl2 = brd1.create('image', ['assets/bowl2.svg', [16.5, -2],[2.0,2.0]],{rotate:function(){return sng.Value()*180/Math.PI}});
			brd1.create('text', [4,5, function(){return sng.Value().toFixed(2);}]);
//var vel = brd1.create('slider', [[-2, 3],[-2, 10],[5, 8, 15]], {name:'velocity', snapWidth:0.01});
			//var vel =brd1.create('input', [0, 8, 8.0, '']);
//var acc = brd1.create('slider', [[0, 9],[4, 9],[0, 1, 90]], {name:'ang'});
			var x = 0;
			var y = 0;
			var r = 20;
			var dt = 0.02;
			var tf=0;
			var i=0;
			var sldd =brd1.create('point',[function(){return r*Math.cos(sng.Value())}, function(){return r*Math.sin(sng.Value())}] , {visible:false});
            var tRot = brd1.create('transform', [function(){return sng.Value();}, sldd], {type:'rotate'}); 
			var bowl1 = brd1.create('image', ['/assets/bowl1.svg',[function(){return r*Math.cos(sng.Value())-2}, function(){return r*Math.sin(sng.Value())}],[2,2]]);
			tRot.bindTo(bowl1);
		    lev.on('drag', function(){return r=Math.sqrt(sld.X()*sld.X()+sld.Y()*sld.Y());});
			var run = function(){
				if(mu.Value()/Math.tan(sng.Value())>=1){
				 i=0;
				}
				else {
				 i=1;
				}
				tf +=i*dt;
				r -= 9.8*Math.sin(sng.Value())*tf*dt;
				x = r*Math.cos(sng.Value());
				y = r*Math.sin(sng.Value());
				bowl1.moveTo([x,y]);
				if(r>=0){
				setTimeout(run, 0);}
            };
			lev.on('up', function(){i=0; tf=0; run()});
		    brd1.create('image', ['/assets/rubish.svg', [-2.75, -2.5],[2.5,2.5]]);
			brd1.create('image', ['/assets/dog.svg', [20, 11.75],[2.5,2.5]]);
			//brd1.create('image', ['assets/bowl1.svg',[function(){return (r-2)*Math.cos(sng.Value())}, function(){return (r-2)*Math.sin(sng.Value())}],[2,2]]);
			
			//brd1.create('text', [0, 5, function(){return '&alpha; = ' +(sng.Value()*180/Math.PI).toFixed(2) +'<sup>o</sup>'}],{fontSize:18, cssStyle:'fontFamily:Oswald'});
    },
 box2: function(){
	 var brd2 = JXG.JSXGraph.initBoard('jxgbox1a',{boundingbox: [-10, 17, 10, -3],keepaspectratio: true, axis:false, ticks:false, grid:false, showCopyright:false, showNavigation:false, zoom:{enabled:false}, pan:{enabled:false}});
     var resize = function () {
            brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
            brd2.fullUpdate();
            };
		brd2.options.layer['point'] =12;
		brd2.options.layer['line'] =9;
		brd2.options.layer['arrow'] =9;
		brd2.options.layer['polygon'] =2;
		brd2.options.layer['image'] =8;
		window.onresize = resize;
	    brd2.suspendUpdate();
	brd2.create('text', [-9, 16, '<b>Static Friction</b>'], {fixed:true, fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      //*************************************************//
      //all var should be lower case.
      //constants
      //var g = 9.8;
      var a =10;
      var b = 0.75;
      var c =-0.25;
      var e =1.5;
      var d = 2;
      var s =-4;
      var g = 9.8;
      brd2.create('axis',[[0.0,4.0],[4.0,4.0]],{name:'F_{ext}(N)', withLabel:true, grid:true, label:{position:'urt', CssStyle:'fontFamily:Oswald', offset:[-40, 20], fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}}});
      brd2.create('axis',[[0.0,4.0],[0.0,9.0]],{name:'F_s(N)', withLabel:true, grid:true, label:{position:'urt', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}}});
      //slider
      var mu = brd2.create('slider', [[-9., 11.0], [-4, 11.0], [0.1, 0.25, 0.5]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'&mu;<sub>s</sub>', size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false,label:{offset:[2,-15], CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}}});
	  //
      var mass = brd2.create('slider', [[-9., 9], [-4, 9], [0.25, 1 , 2]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'m(kg)',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false,label:{offset:[2,-15], CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}}});
      //base
      var base = brd2.create('polygon', [[-12,-0.78],[12,-0.75],[12, -3],[-12, -3]],{strokeWidth:0, fillcolor:'orange',vertices:{visible:false},withLines:false, fillOpacity:0.75,fixed:true});
	  //image
	var Pt0= brd2.create('point',[5, 0.],{name:'',fontSize:'28',size:2,fillColor:'yellow',strokeColor:'black',shadow:true, label:{CssStyle:'fontFamily:Oswald',offset:[0,35], fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}}});
	brd2.create('image', ['/assets/grass.svg', [10.1, -1],[2, 1]], {opacity:1, fixed:true});
	brd2.create('image', ['/assets/grass.svg', [8.1, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [6.1, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [4.1, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [2.1, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [0.1, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [-1.9, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [-3.9, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [-5.9, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [-7.9, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/grass.svg', [-9.9, -1],[2, 1]], {opacity:1, fixed:true});
	  brd2.create('image', ['/assets/mower.svg', [function(){return Pt0.X()-1}, -0.75],[2, 2]], {opacity:1, fixed:true});
	 var restart= brd2.create('image', ['/assets/reloading.svg', [-6, 5],[2, 2]], {opacity:1, fixed:true});
	  //Glider
      var pathAB = brd2.create('segment',[[4,1.05],[-28, 1.05]],{strokeWidth:4,straightFirst:false, straightLast:true, strokeColor:'black', fixed:true, visible:false});
	  //Force Line
	  var fa=brd2.create('point',[4,1.05],{name:'', size:0, fixed:true});
	  var fb=brd2.create('glider',[4,1.05, pathAB],{name:'F_{ext} (Drag me!)',face:'triangleleft',size:8,fillColor:'yellow',strokeColor:'black',shadow:true, label:{CssStyle:'fontFamily:Oswald',offset:[-120,0], fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}}});
	  var f = brd2.create('arrow',[fa,fb],{strokeWidth:4,straightFirst:false, straightLast:false, strokeColor:'green', fixed:true});
	  
	  //Friction
	  var fi= brd2.create('point',[6,-0.25],{name:'',fontSize:'28', size:0});
	  var fj = brd2.create('point',[function(){return fa.X()-fb.X()+fi.X();},-0.25],{name:'',fontSize:'28', size:0});
      brd2.create('arrow',[fi,fj],{strokeWidth:4,straightFirst:false, straightLast:false, strokeColor:'red', fixed:true});
      var cen= brd2.create('point',[function(){return fa.X()-fb.X()+fi.X();}, -0.25],{name:'F_s',fontSize:'28', size:0, label:{CssStyle:'fontFamily:Oswald', offset:[0,15], fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}}
	  });
	 
      var lim = brd2.create('line',[[0,function(){return mu.Value()*mass.Value()*g+5}],[6,function(){return mu.Value()*mass.Value()*g+5}]],{strokeWidth:2,straightFirst:false, straightLast:true, strokeColor:'red', fixed:true, dash:1});
      //friction
	  brd2.create('text', [2, function(){return mu.Value()*mass.Value()*g+5.5}, 'Limiting Static Friction'], {fixed:true, fontSize:function(){return 16*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      //var wa = brd2.create('point',[function(){return Pt0.X();},-0.75],{name:'',fontSize:'28', size:2, fixed:true});
      var wb = brd2.create('point',[function(){return Pt0.X();},1.25],{name:'R<sub>N</sub>', size:0, fixed:true,label:{CssStyle:'fontFamily:Oswald',offset:[0,15], fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}} });
      //var na = brd2.create('point',[-s,c],{name:'',fontSize:'28', size:0, fixed:true});
      var nb = brd2.create('point',[function(){return Pt0.X();},-1.25],{name:'W=mg', size:0, fixed:true, label:{CssStyle:'fontFamily:Oswald',offset:[0,-10], fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}}});
      var ff= brd2.create('point',[function(){return Math.min(-fb.X()-2*s+3.5, mu.Value()*mass.Value()*g)-3.5;}, c],{name:'',fontSize:'28', size:0, fixed:true});
      //
	  var i =1;
	  setInterval(function(){
      if(Math.abs(fb.X()-4.0)>= mu.Value()*mass.Value()*g){
        Pt0.moveTo([-36, 0], 9000);
        fb.moveTo([-36-mu.Value()*mass.Value()*g, 1.05],9000);
        fa.moveTo([-36, 1.05],9000);
		fi.moveTo([-36, c],9000);;
		fj.moveTo([-36+mu.Value()*mass.Value()*g, c],9000);};
		},1200);
      //Start Over Button
	  restart.on('down', function(){i=0; Pt0.moveTo([5, 0],1);fi.moveTo([6, -0.25],1);fa.moveTo([4, 1.05],1); fb.moveTo([4, 1.05],1);});
	  mu.on('down', function(){i=0; Pt0.moveTo([5, 0],1);fi.moveTo([6, -0.25],1);fa.moveTo([4, 1.05],1); fb.moveTo([4, 1.05],1);});
	  mass.on('down', function(){i=0; Pt0.moveTo([5, 0],1);fi.moveTo([6, -0.25],1);fa.moveTo([4, 1.05],1); fb.moveTo([4, 1.05],1);});
	  //
	  //
      function val(x){
          if (x <= mu.Value()*mass.Value()*g){
            return x;}
          else{
            return mu.Value()*mass.Value()*g;}};
      //brd2.addEvent(mve);
      var w = brd2.create('arrow',[Pt0, wb],{strokeWidth:4,straightFirst:false, straightLast:false, strokeColor:'black', fixed:true});
      var n = brd2.create('arrow',[Pt0, nb],{strokeWidth:4,straightFirst:false, straightLast:false, strokeColor:'black', fixed:true});
      brd2.create('functiongraph',[function(x){return val(x)+5}, 0, function(){return Math.min(-fb.X()+4, mu.Value()*mass.Value()*g+0.5)}],{strokeColor:'black', strokeWidth:4});
      brd2.unsuspendUpdate();
 }
}
export default Boxes;
